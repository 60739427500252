import React, { useState, useEffect, Component } from 'react';
import BookCover from "../images/cover-reading-books-wallpapers-19431.jpg"
import { callingAPI } from '../service';
import { FaPlusCircle, FaImages, FaTimes, FaTrash } from "react-icons/fa";
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Delete from "../images/deleteicon.png"
import { uploadToS3 } from '../uploadToS3';

import AWS from 'aws-sdk';
var s3
var bucketName = ""
var s3Url
function Status(props) {
    const [list, setList] = useState([])
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [file, setFile] = useState('');
    const [imageName, setImageName] = useState('');
    const [imgObject, setImgObject] = useState('');
    const [environmentType, setEnvironmentType] = useState('');
    const [categoryImagePath, setCategoryImagePath] = useState('');
    const [cofigDetails, setConfigDetails] = useState('')
    const [singleClick, setSingleClick] = useState(true)


    useEffect(() => {
        handlePostList();
        getConfiguration();
    }, [])


    const handlePostList = () => {
        const PostData = {
            "user_id": window.localStorage.getItem("userId"),

        }
        callingAPI('post/postList', PostData).then((response) => {
            console.log(("............................... POSTLIST", response));
            if (response.data.result.length > 0 && response.data.result[0].hasOwnProperty('stories')) {
                setList(response.data.result[0].stories)
            }
            else {
                setList([])
            }
        })
    }

    const handlePostSave = async () => {
        // debugger
        setSingleClick(false)
        if (!title && !description && !imageName) {
            toast.error("Post Data Required", { containerId: "S" })
            setSingleClick(true)
            return false
        }
        else {
            try {
                var postData = {
                    "user_id": window.localStorage.getItem("userId"),
                    "createdOnDate": new Date().getTime(),
                    "stories": [
                        {
                            "title": title,
                            "postId": "",
                            "description": description,
                            "image": imageName
                        }
                    ]
                }
                console.log("postData", postData);
                const response = await callingAPI('post/postSave', postData);

                if (response.data.success == 1) {


                    if (file.length !== 0) {
                        let isUploaded = await imageS3Save(imageName, file);
                        // if (isUploaded === true) {
                        //     setTimeout(() => {
                        //         hidePopup('statusId');
                        //     }, 2000);
                        // }
                    }
                    setTitle("");
                    setImageName("");
                    setFile("");
                    setDescription("");
                    setImgObject("")
                    handlePostList();
                    toast.success("Added successfully", { containerId: "S" })
                    setTimeout(() => {
                        hidePopup('statusId');
                    }, 2000);
                    setSingleClick(true)


                }
            }
            catch (error) {
                console.error("Error:", error);
            }
        }
    }



    const handleImageUploadOnChange = (e) => {
        if (!e.target.files[0].name.match(/\.(jpg|JPG|jpeg|JPEG|png|PNG)$/)) {
            alert("Please attach jpg,jpeg or png files");
            return false;
        }
        setFile(e.target.files[0]);
        setImageName(e.target.files[0].name);
        const obj = URL.createObjectURL(e.target.files[0])
        setImgObject(obj);
    };

    const imageS3Save = async(imageName, file) => {
        s3Url = await uploadToS3(file, environmentType + '/post', bucketName)
        return true;
        //     return new Promise((resolve, reject) => {

        //         var S3BucketMainFolder = environmentType + '/post';
        //         var fullFilePath = S3BucketMainFolder + '/' + imageName;

        //         s3.upload(
        //             {
        //                 Key: fullFilePath,
        //                 Body: file,
        //                 ACL: 'public-read',
        //             },
        //             (err, data) => {
        //                 if (err) {
        //                     alert('There was an error uploading your photo: ' + err.message);
        //                     reject(err);
        //                 } else {
        //                     // alert("File Uploaded Successfully")
        //                     resolve(true);
        //                     // document.getElementById('uploadButton').style.display = 'block';

        //                 }
        //             }
        //         );
        //     })
    };

    const getConfiguration = () => {
        return new Promise((resolve, reject) => {
            try {
                const PostData = {
                };
                callingAPI('configurationData', PostData).then((res) => {
                    setConfigDetails(res.data.result[0]);
                    const getConfigValue = (configKey) => {
                        return res.data.result[0].configDetails
                            ? res.data.result[0].configDetails.find((config) => config.config_key === configKey)?.config_value
                            : null;
                    };
                    setEnvironmentType(getConfigValue("S3BucketMainFolder"));
                    setCategoryImagePath(getConfigValue("bookImagePath"));
                    AWS.config.update({
                        accessKeyId: getConfigValue("AWSAccessKeyId"),
                        secretAccessKey: getConfigValue("AWSSecretKey"),
                        region: getConfigValue("s3Region"),
                    });
                    bucketName = getConfigValue("s3bucket")
                    var bucketParams = {
                        Bucket: getConfigValue("s3bucket"),
                    };
                    s3 = new AWS.S3({ apiVersion: '2006-03-01', params: bucketParams });
                    resolve(s3)
                })

            }
            catch (e) {
                console.error("Error in getConfiguration", e)
                reject(e)
            }
        })

    }

    const handleDelete = (deleteId) => {
        setSingleClick(false)
        const postData = {
            "postId": deleteId
        }
        callingAPI('post/deletePostDetails', postData).then((response) => {
            console.log("response", response);
            if (response.data.success == "1") {
                toast.success("Deleted successfully", { containerId: "S" })
                handlePostList();
                setSingleClick(true)
            }
            else {
                setSingleClick(true)
            }
        })

    }


    const showPopup = (id, data) => {
        const popup = document.getElementById(id);
        if (popup) {
            popup.style.display = 'block';
        }
    }
    const hidePopup = (id) => {
        const popup = document.getElementById(id);
        if (popup) {
            popup.style.display = 'none';
        }
        setTitle("");
        setDescription("");
        setImgObject("")
        setImageName("");
        setFile("");
    };
    return (
        <div className="status-wrapper">
            <ToastContainer
                enableMultiContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                containerId={"S"}
                theme="colored" />
            <div className="create-status">
                <span className="Addplus-btn" onClick={() => showPopup('statusId')}><FaPlusCircle /></span>
                <div className="create-status-text">
                    <h3 className="">Create Status</h3>
                    <p>Ask a question, express a thought you wish to discuss or engage with followers</p>
                </div>
            </div>
            <div className=" card-columns">

                {list && list.map((data) =>
                    <div className="status-section-content position-relative">
                        <div className="" onClick={singleClick === true ? () => handleDelete(data.id) : null}><FaTrash /></div>
                        <h3 className="">{data.title}</h3>
                        {data.image ?
                            <img src={data.image} alt="" className='newsImg' />
                            : ""}
                        <p className="">{data.description}</p>
                        {/* <a className='newsLink'>http://localhost:3001/static/media/860295_1704701727805.60d1cf167eb31a4ef5a3.jpg</a> */}
                        {/* <span className='newsTime'>posted on: {moment(data.created_on).format('DD-MM-YYYY hh:mm')}</span> */}
                    </div>)}




                {/* 
                <div className="status-section-content">

                    <h3 className="">What is Lorem Ipsum  simply dummy text</h3>
                    <p className="">Please  click the link below
                    </p>
                    <a className='newsLink'>http://localhost:3001/static/media/860295_1704701727805.60d1cf167eb31a4ef5a3.jpg</a>
                    <span className='newsTime'>110 days ago</span>
                </div>
                <div className="status-section-content">
                    <h3 className="">What is Lorem</h3>
                    <p className="">Please read the article about  simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type.
                    </p>
                    <a className='newsLink'>http://localhost:3001/static/media/860295_1704701727805.60d1cf167eb31a4ef5a3.jpg</a>
                    <span className='newsTime'>110 days ago</span>
                </div>

                <div className="status-section-content">
                    <h3 className="">What is Lorem Ipsum </h3>

                    <p className="">Please read the article about  simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type.
                    </p>
                    <a className='newsLink'>http://localhost:3001/static/media/860295_1704701727805.60d1cf167eb31a4ef5a3.jpg</a>
                    <span className='newsTime'>110 days ago</span>
                </div>
                <div className="status-section-content">
                    <h3 className="">What is Lorem Ipsum </h3>

                    <p className="">Please read the article about  simply dummy text of the printing Please read the article about  simply dummy text of the printing Please read the article about  simply dummy text of the printing Please read the article about  simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type.
                    </p>
                    <a className='newsLink'>http://localhost:3001/static/media/860295_1704701727805.60d1cf167eb31a4ef5a3.jpg</a>
                    <span className='newsTime'>110 days ago</span>
                </div>

                <div className="status-section-content">
                    <h3 className="">What is Lorem Ipsum  simple</h3>
                    <p className="">Please read the article about  simply dummy text of the printing and typesetting industry.
                    </p>
                    <img src={BookCover} alt="" className='newsImg' />

                    <a className='newsLink'>http://localhost:3001/static/media/860295_1704701727805.60d1cf167eb31a4ef5a3.jpg</a>
                    <span className='newsTime'>110 days ago</span>
                </div>
                <div className="status-section-content">
                    <h3 className="">What is Lorem Ipsum </h3>

                    <p className="">Please read the article about  simply dummy text of the printing Please read the article about  simply dummy text of the printing and typesetting industry.
                    </p>
                    <a className='newsLink'>http://localhost:3001/static/media/860295_1704701727805.60d1cf167eb31a4ef5a3.jpg</a>
                    <span className='newsTime'>110 days ago</span>
                </div> */}


            </div>

            <div className="popup createStatusPopup" id="statusId">
                <section className=''>
                    <div className="close-popup" onClick={() => hidePopup('statusId')}><FaTimes /></div>
                    <div className="">
                        <h3 className="addpost-statushead">Add Post</h3>
                    </div>

                    <div className="create-status-sec">
                        <div className="form-group">
                            <label>Title</label>
                            <input type="text" className="form-control" id="title" placeholder="Enter Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label>Description</label>
                            <textarea name="description" id="description" className="form-control" placeholder='Enter Description' value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                        </div>
                        <div className="form-group btn-section">
                            {/* <button className='btn-photos'><FaImages />Photos</button> */}
                            <span id="uploadButton" className="">
                                <input
                                    type="file"
                                    id="file1"
                                    title="Upload"
                                    onChange={(e) => handleImageUploadOnChange(e)}
                                    hidden
                                />
                                <label for="file1" className="btn-photos">
                                    <FaImages style={{ color: "#fff" }} />Photos
                                </label>
                                {imgObject &&
                                    <img src={imgObject} alt="" width={40} height={40} style={{ marginLeft: "10px" }} />}

                            </span>

                        </div>
                        <div className="form-group btn-postsec">
                            <button className='btn-post'
                                // onClick={ !singleClick ? () => handlePostSave() : null}
                                onClick={singleClick === true ? () => handlePostSave() : null}
                            >Post</button>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )

}
export default Status